import React from 'react'
import { useSiteMetadata } from '../utils'

const Logo = () => {
  const site = useSiteMetadata();

  const textStyle = {
    fill: '#000000',
    fillOpacity: 1,
    stroke: 'none',
  };

  return (
    <svg viewBox="10 10 80 80">
      <title>{site.title}</title>
      <circle cx="50" cy="50" r="30" fill="#333" />
      <g
        transform="scale(0.8) translate(16, 34)"
      >
        <g
           transform="translate(-22.070125,-63.104461)"
           style={{...textStyle, fill: '#ffffff'}}
           aria-label="JANA">
          <path
             d="m 38.76202,88.076616 0.904335,-0.124023 q 0.03617,0.868161 0.325561,1.188554 0.289387,0.320393 0.800982,0.320393 0.377236,0 0.651121,-0.170532 0.273884,-0.175699 0.377236,-0.470254 0.103353,-0.299722 0.103353,-0.950843 v -5.219303 h 1.00252 v 5.162459 q 0,0.950843 -0.232544,1.472774 -0.227375,0.52193 -0.728635,0.795814 -0.496092,0.273885 -1.167884,0.273885 -0.997352,0 -1.529617,-0.573607 -0.527098,-0.573607 -0.506428,-1.705317 z" />
          <path
             d="m 43.733277,90.226349 2.909374,-7.575741 h 1.080034 l 3.100576,7.575741 h -1.142045 l -0.883664,-2.294426 h -3.167756 l -0.831988,2.294426 z m 2.185906,-3.110911 h 2.568311 l -0.790647,-2.098057 q -0.361734,-0.956011 -0.537433,-1.570958 -0.144694,0.728635 -0.408243,1.446935 z" />
          <path
             d="m 51.624244,90.226349 v -7.575741 h 1.028358 l 3.979072,5.947938 v -5.947938 h 0.961179 v 7.575741 h -1.028358 l -3.979072,-5.953106 v 5.953106 z" />
          <path
             d="m 58.450678,90.226349 2.909374,-7.575741 h 1.080034 l 3.100576,7.575741 h -1.142046 l -0.883664,-2.294426 h -3.167755 l -0.831988,2.294426 z m 2.185906,-3.110911 h 2.568311 l -0.790647,-2.098057 q -0.361734,-0.956011 -0.537434,-1.570958 -0.144693,0.728635 -0.408242,1.446935 z" />
        </g>
        <g
           transform="translate(-56.719115,-76.996082)"
           style={{...textStyle, fill: '#9c9c9c'}}
           aria-label="FORKMANN">
          <path
             d="m 73.930883,113.28121 v -7.57574 h 5.110782 v 0.894 h -4.108263 v 2.3461 h 3.555327 v 0.894 h -3.555327 v 3.44164 z" />
          <path
             d="m 80.044185,109.59153 q 0,-1.88619 1.012855,-2.95072 1.012855,-1.0697 2.614819,-1.0697 1.049028,0 1.891351,0.50126 0.842324,0.50126 1.281572,1.40043 0.444416,0.894 0.444416,2.03088 0,1.15238 -0.465087,2.06188 -0.465086,0.9095 -1.317745,1.37976 -0.852658,0.46508 -1.839675,0.46508 -1.069698,0 -1.912022,-0.51676 -0.842323,-0.51676 -1.276403,-1.41076 -0.434081,-0.894 -0.434081,-1.89135 z m 1.033525,0.0155 q 0,1.36942 0.733803,2.16007 0.738971,0.78548 1.850011,0.78548 1.13171,0 1.860345,-0.79582 0.733803,-0.79581 0.733803,-2.25825 0,-0.92501 -0.315225,-1.6123 -0.310057,-0.69246 -0.91467,-1.0697 -0.599445,-0.3824 -1.34875,-0.3824 -1.064532,0 -1.834508,0.7338 -0.764809,0.72863 -0.764809,2.43912 z" />
          <path
             d="m 88.59144,113.28121 v -7.57574 h 3.358957 q 1.012855,0 1.539953,0.20671 0.527098,0.20153 0.842323,0.7183 0.315225,0.51676 0.315225,1.14204 0,0.80615 -0.52193,1.35909 -0.52193,0.55293 -1.6123,0.70279 0.397908,0.19121 0.604613,0.37724 0.439248,0.40308 0.831988,1.00769 l 1.317745,2.06188 h -1.260901 l -1.00252,-1.57612 q -0.439248,-0.68213 -0.723468,-1.04386 -0.284219,-0.36174 -0.511595,-0.50643 -0.222208,-0.1447 -0.454751,-0.20154 -0.170532,-0.0362 -0.558104,-0.0362 h -1.162716 v 3.36412 z m 1.002519,-4.23228 h 2.154901 q 0.687294,0 1.074866,-0.13953 0.387572,-0.14469 0.58911,-0.45475 0.201537,-0.31523 0.201537,-0.68213 0,-0.53743 -0.39274,-0.88366 -0.387572,-0.34623 -1.229895,-0.34623 h -2.397779 z" />
          <path
             d="m 96.182683,113.28121 v -7.57574 h 1.00252 v 3.75687 l 3.762037,-3.75687 h 1.35908 l -3.178089,3.06957 3.317619,4.50617 h -1.32292 l -2.697497,-3.83438 -1.24023,1.20923 v 2.62515 z" />
          <path
             d="m 103.26233,113.28121 v -7.57574 h 1.50895 l 1.79317,5.364 q 0.24804,0.7493 0.36173,1.12137 0.12919,-0.41341 0.40307,-1.21439 l 1.81384,-5.27098 h 1.34875 v 7.57574 h -0.96634 v -6.34068 l -2.20141,6.34068 h -0.90434 l -2.19107,-6.4492 v 6.4492 z" />
          <path
             d="m 111.28765,113.28121 2.90938,-7.57574 h 1.08003 l 3.10058,7.57574 h -1.14205 l -0.88366,-2.29442 h -3.16776 l -0.83198,2.29442 z m 2.18591,-3.11091 h 2.56831 l -0.79065,-2.09806 q -0.36173,-0.95601 -0.53743,-1.57095 -0.14469,0.72863 -0.40824,1.44693 z" />
          <path
             d="m 119.17862,113.28121 v -7.57574 h 1.02836 l 3.97907,5.94794 v -5.94794 h 0.96118 v 7.57574 h -1.02835 l -3.97908,-5.9531 v 5.9531 z" />
          <path
             d="m 126.82671,113.28121 v -7.57574 h 1.02836 l 3.97907,5.94794 v -5.94794 h 0.96118 v 7.57574 h -1.02836 l -3.97907,-5.9531 v 5.9531 z" />
        </g>
      </g>
    </svg>
  );
}

export default Logo
