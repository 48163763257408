import { useStaticQuery, graphql } from 'gatsby';

const siteMetadataQuery = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        title
        titleSeo
        titleShort
        headline
        url
        language
        logo
        description
        author
        ogSiteName
        ogLanguage
      }
    }
  }
`
export const useSiteMetadata = () => {
  const { site: { siteMetadata } } = useStaticQuery(siteMetadataQuery);
  return siteMetadata;
};
